.yitiModal {
  :global {
    .ant-modal-body {
      padding: 0;
    }
    .ant-collapse-content-box {
      padding: 16px 24px 32px !important;
    }
    .ant-card-body {
      padding: 12px 16px 16px;
    }
  }
  .header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 70px 0 24px;
    height: 60px;
    box-shadow: 0px 1px 1px 0px #ebedf0;
    .title {
      font-size: 18px;
      color: #242d3f;
      font-weight: 600;
    }
    .createTime {
      color: #898f9b;
      font-size: 14px;
      font-weight: 400;
      margin-left: 24px;
    }
  }

  .body {
    height: calc(90vh - 60px - 72px);
    overflow: auto;
    background-color: #f3f6fa;
    .collapse {
      background-color: #f3f6fa;
      :global {
        .ant-collapse-header {
          background-color: #fff;
          user-select: none;
        }
      }
      .collapseHeader {
        display: flex;
        align-items: center;
        padding-left: 10px;
        color: #242d3f;
        font-weight: 700;
        font-size: 16px;
        img {
          height: 20px;
          margin-right: 14px;
        }
      }
      .ptitle {
        color: #898f9b;
        font-size: 14px;
        font-weight: 700;
        margin: 16px 0 8px;
        padding-left: 16px;
      }
      .formLabel {
        color: #242d3f;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .formValue {
        color: #242d3f;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .footer {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
  }
}

.displayFile {
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  position: relative;
  margin-bottom: 8px;
  :global {
    .iconfont {
      font-size: 15px;
      color: #f38ca7;
    }
  }
  .fileName {
    flex: 1;
    padding: 0 8px;
    color: #242d3f;
    font-size: 14px;
    font-weight: 400;
  }
  .downloadBtn {
    color: #ff79a5;
    font-size: 14px;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #ff62cf 0%, #ff9471 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 0.2;
  }
}

.displayImg {
  width: 174px;
  height: 80px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.displayGroupsProject {
  .GroupsProjectCard {
    padding: 0 12px 8px 0;
    background-color: #f7f8fa;
    border-radius: 8px;
    position: relative;
    margin-bottom: 16px;
    display: flex;
    overflow: hidden;

    .seqId {
      background-color: #ebedf0;
      height: 20px;
      padding: 0 6px;
      border-radius: 0 0 8px 0;
      line-height: 20px;
      font-size: 12px;
      color: #898f9b;
      font-weight: 700;
    }
    .content {
      margin-left: 6px;
      flex: 1;
    }
    .row1 {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    .title {
      flex: 1;
      color: #242d3f;
      word-break: break-all;
      word-wrap: break-word;
      font-weight: 700;
      font-size: 16px;
      margin-right: 12px;
    }
    .level {
      width: 204px;
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 400;
      text-align: right;
    }
    .row2 {
      display: flex;
      align-items: center;
    }
    .tags {
      flex: 1;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 10px;
      .tag {
        display: inline-block;
        border-radius: 4px;
        background-color: rgba(243, 140, 167, 0.1);
        color: #f38ca7;
        font-size: 12px;
        font-weight: 400;
        padding: 4px 8px;
        margin-bottom: 8px;
        margin-right: 8px;
      }
    }
    .fee {
      color: #3d3d3d;
      font-size: 14px;
      font-weight: 400;
    }
    .row3 {
      display: flex;
      align-items: center;
      border-top: 1px dashed #ebedf0;
      padding-top: 12px;
    }
    .remark {
      flex: 1;
      color: #3d3d3d;
      font-size: 14px;
      font-weight: 400;
      margin-right: 12px;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;