.content {
  display: inline-block;
  width: 180px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  cursor: pointer;
}

.picker {
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  :global {
    div[title='rgba(0,0,0,0)'] {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=')
        left center !important;
    }

    div[title='none'] {
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        inset-inline-end: -1px;
        top: -1px;
        display: block;
        width: 40px;
        height: 2px;
        transform-origin: calc(100% - 1px) 1px;
        transform: rotate(-45deg);
        background-color: red;
      }
    }
  }
}

.preset-item {
  width: 16px;
  height: 16px;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  position: relative;
  overflow: hidden;

  &[title='rgba(0,0,0,0)'] {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=')
      left center !important;
  }

  &[title='none'] {
    &::before {
      content: '';
      position: absolute;
      inset-inline-end: -1px;
      top: -1px;
      display: block;
      width: 40px;
      height: 2px;
      transform-origin: calc(100% - 1px) 1px;
      transform: rotate(-45deg);
      background-color: red;
    }
  }
}

.preset-wrapper {
  padding: 10px 0 0 10px;
  border-top: 1px solid rgb(238, 238, 238);
}

.color-item {
  &[title='unset'] {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 40px;
      height: 2px;
      transform: rotate(-45deg);
      background-color: red;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;