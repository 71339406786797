@tailwind base;

@tailwind components;

@tailwind utilities;

*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::-webkit-backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

.pi-light-theme,
  .pi-light-theme *,
  :root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;
  --radius: 0.5rem;
}

.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;
  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;
  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;
  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;
  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;
  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;
  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;
  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
}

*{
  border-color: hsl(var(--border));
}

body{
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

.container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (min-width: 1400px){
  .container{
    max-width: 1400px;
  }
}

.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none{
  pointer-events: none;
}

.pointer-events-auto{
  pointer-events: auto;
}

.\!visible{
  visibility: visible !important;
}

.visible{
  visibility: visible;
}

.invisible{
  visibility: hidden;
}

.\!collapse{
  visibility: collapse !important;
}

.collapse{
  visibility: collapse;
}

.static{
  position: static;
}

.fixed{
  position: fixed;
}

.absolute{
  position: absolute;
}

.relative{
  position: relative;
}

.sticky{
  position: sticky;
}

.inset-0{
  inset: 0px;
}

.-left-14{
  left: -3.5rem;
}

.-left-\[134px\]{
  left: -134px;
}

.-right-2{
  right: -0.5rem;
}

.-top-2{
  top: -0.5rem;
}

.-top-8{
  top: -2rem;
}

.bottom-0{
  bottom: 0px;
}

.left-0{
  left: 0px;
}

.left-2{
  left: 0.5rem;
}

.left-\[-148px\]{
  left: -148px;
}

.left-\[50\%\]{
  left: 50%;
}

.right-0{
  right: 0px;
}

.right-2{
  right: 0.5rem;
}

.right-4{
  right: 1rem;
}

.top-0{
  top: 0px;
}

.top-2{
  top: 0.5rem;
}

.top-4{
  top: 1rem;
}

.top-\[14px\]{
  top: 14px;
}

.top-\[50\%\]{
  top: 50%;
}

.z-10{
  z-index: 10;
}

.z-50{
  z-index: 50;
}

.z-\[1000\]{
  z-index: 1000;
}

.z-\[9999\]{
  z-index: 9999;
}

.-mx-0\.5{
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.-mx-1{
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.mx-1{
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3{
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mb-1\.5{
  margin-bottom: -0.375rem;
}

.mb-0{
  margin-bottom: 0px;
}

.mb-1{
  margin-bottom: 0.25rem;
}

.mb-10{
  margin-bottom: 2.5rem;
}

.mb-2{
  margin-bottom: 0.5rem;
}

.mb-3{
  margin-bottom: 0.75rem;
}

.mb-4{
  margin-bottom: 1rem;
}

.mb-5{
  margin-bottom: 1.25rem;
}

.mb-6{
  margin-bottom: 1.5rem;
}

.mb-\[18px\]{
  margin-bottom: 18px;
}

.mb-\[22px\]{
  margin-bottom: 22px;
}

.mb-\[24px\]{
  margin-bottom: 24px;
}

.mb-\[40px\]{
  margin-bottom: 40px;
}

.mb-\[70px\]{
  margin-bottom: 70px;
}

.mb-\[8px\]{
  margin-bottom: 8px;
}

.ml-1{
  margin-left: 0.25rem;
}

.ml-2{
  margin-left: 0.5rem;
}

.ml-4{
  margin-left: 1rem;
}

.ml-6{
  margin-left: 1.5rem;
}

.ml-\[6px\]{
  margin-left: 6px;
}

.ml-auto{
  margin-left: auto;
}

.mr-0{
  margin-right: 0px;
}

.mr-1{
  margin-right: 0.25rem;
}

.mr-12{
  margin-right: 3rem;
}

.mr-2{
  margin-right: 0.5rem;
}

.mr-20{
  margin-right: 5rem;
}

.mr-3{
  margin-right: 0.75rem;
}

.mr-4{
  margin-right: 1rem;
}

.mr-5{
  margin-right: 1.25rem;
}

.mr-6{
  margin-right: 1.5rem;
}

.mr-7{
  margin-right: 1.75rem;
}

.mr-\[10px\]{
  margin-right: 10px;
}

.mr-\[14px\]{
  margin-right: 14px;
}

.mr-\[15px\]{
  margin-right: 15px;
}

.mr-\[19px\]{
  margin-right: 19px;
}

.mr-\[2px\]{
  margin-right: 2px;
}

.mr-\[4px\]{
  margin-right: 4px;
}

.mr-\[6px\]{
  margin-right: 6px;
}

.mt-0{
  margin-top: 0px;
}

.mt-1{
  margin-top: 0.25rem;
}

.mt-2{
  margin-top: 0.5rem;
}

.mt-3{
  margin-top: 0.75rem;
}

.mt-4{
  margin-top: 1rem;
}

.mt-\[21px\]{
  margin-top: 21px;
}

.box-border{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.block{
  display: block;
}

.inline-block{
  display: inline-block;
}

.inline{
  display: inline;
}

.flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inline-flex{
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.\!table{
  display: table !important;
}

.table{
  display: table;
}

.table-cell{
  display: table-cell;
}

.grid{
  display: grid;
}

.contents{
  display: contents;
}

.list-item{
  display: list-item;
}

.\!hidden{
  display: none !important;
}

.hidden{
  display: none;
}

.size-8{
  width: 2rem;
  height: 2rem;
}

.size-\[20px\]{
  width: 20px;
  height: 20px;
}

.size-\[21px\]{
  width: 21px;
  height: 21px;
}

.h-0{
  height: 0px;
}

.h-10{
  height: 2.5rem;
}

.h-11{
  height: 2.75rem;
}

.h-12{
  height: 3rem;
}

.h-14{
  height: 3.5rem;
}

.h-16{
  height: 4rem;
}

.h-2{
  height: 0.5rem;
}

.h-2\.5{
  height: 0.625rem;
}

.h-20{
  height: 5rem;
}

.h-24{
  height: 6rem;
}

.h-3\.5{
  height: 0.875rem;
}

.h-4{
  height: 1rem;
}

.h-5{
  height: 1.25rem;
}

.h-6{
  height: 1.5rem;
}

.h-7{
  height: 1.75rem;
}

.h-8{
  height: 2rem;
}

.h-9{
  height: 2.25rem;
}

.h-\[10px\]{
  height: 10px;
}

.h-\[120px\]{
  height: 120px;
}

.h-\[136px\]{
  height: 136px;
}

.h-\[14px\]{
  height: 14px;
}

.h-\[168px\]{
  height: 168px;
}

.h-\[180px\]{
  height: 180px;
}

.h-\[18px\]{
  height: 18px;
}

.h-\[20px\]{
  height: 20px;
}

.h-\[22px\]{
  height: 22px;
}

.h-\[250px\]{
  height: 250px;
}

.h-\[28px\]{
  height: 28px;
}

.h-\[30px\]{
  height: 30px;
}

.h-\[32px\]{
  height: 32px;
}

.h-\[376px\]{
  height: 376px;
}

.h-\[40px\]{
  height: 40px;
}

.h-\[46px\]{
  height: 46px;
}

.h-\[473px\]{
  height: 473px;
}

.h-\[480px\]{
  height: 480px;
}

.h-\[500px\]{
  height: 500px;
}

.h-\[52px\]{
  height: 52px;
}

.h-\[580px\]{
  height: 580px;
}

.h-\[5px\]{
  height: 5px;
}

.h-\[600px\]{
  height: 600px;
}

.h-\[60px\]{
  height: 60px;
}

.h-\[6px\]{
  height: 6px;
}

.h-\[720px\]{
  height: 720px;
}

.h-\[746px\]{
  height: 746px;
}

.h-\[calc\(100\%-38px\)\]{
  height: calc(100% - 38px);
}

.h-\[calc\(100\%-72px\)\]{
  height: calc(100% - 72px);
}

.h-fit{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.h-full{
  height: 100%;
}

.h-px{
  height: 1px;
}

.h-screen{
  height: 100vh;
}

.max-h-\[200px\]{
  max-height: 200px;
}

.max-h-\[300px\]{
  max-height: 300px;
}

.max-h-\[600px\]{
  max-height: 600px;
}

.max-h-screen{
  max-height: 100vh;
}

.min-h-\[120px\]{
  min-height: 120px;
}

.min-h-\[160px\]{
  min-height: 160px;
}

.min-h-full{
  min-height: 100%;
}

.min-h-screen{
  min-height: 100vh;
}

.w-0{
  width: 0px;
}

.w-0\.5{
  width: 0.125rem;
}

.w-1{
  width: 0.25rem;
}

.w-1\/2{
  width: 50%;
}

.w-1\/3{
  width: 33.333333%;
}

.w-10{
  width: 2.5rem;
}

.w-12{
  width: 3rem;
}

.w-14{
  width: 3.5rem;
}

.w-2{
  width: 0.5rem;
}

.w-2\.5{
  width: 0.625rem;
}

.w-28{
  width: 7rem;
}

.w-3{
  width: 0.75rem;
}

.w-3\.5{
  width: 0.875rem;
}

.w-4{
  width: 1rem;
}

.w-40{
  width: 10rem;
}

.w-5{
  width: 1.25rem;
}

.w-56{
  width: 14rem;
}

.w-6{
  width: 1.5rem;
}

.w-60{
  width: 15rem;
}

.w-64{
  width: 16rem;
}

.w-72{
  width: 18rem;
}

.w-8{
  width: 2rem;
}

.w-80{
  width: 20rem;
}

.w-9{
  width: 2.25rem;
}

.w-\[100px\]{
  width: 100px;
}

.w-\[1016px\]{
  width: 1016px;
}

.w-\[110px\]{
  width: 110px;
}

.w-\[120px\]{
  width: 120px;
}

.w-\[156px\]{
  width: 156px;
}

.w-\[168px\]{
  width: 168px;
}

.w-\[180px\]{
  width: 180px;
}

.w-\[18px\]{
  width: 18px;
}

.w-\[1px\]{
  width: 1px;
}

.w-\[200px\]{
  width: 200px;
}

.w-\[22px\]{
  width: 22px;
}

.w-\[240px\]{
  width: 240px;
}

.w-\[264px\]{
  width: 264px;
}

.w-\[270px\]{
  width: 270px;
}

.w-\[280px\]{
  width: 280px;
}

.w-\[288px\]{
  width: 288px;
}

.w-\[28px\]{
  width: 28px;
}

.w-\[29px\]{
  width: 29px;
}

.w-\[30px\]{
  width: 30px;
}

.w-\[32px\]{
  width: 32px;
}

.w-\[500px\]{
  width: 500px;
}

.w-\[568px\]{
  width: 568px;
}

.w-\[56px\]{
  width: 56px;
}

.w-\[60px\]{
  width: 60px;
}

.w-\[618px\]{
  width: 618px;
}

.w-\[6px\]{
  width: 6px;
}

.w-\[96px\]{
  width: 96px;
}

.w-\[calc\(100\%\+8px\)\]{
  width: calc(100% + 8px);
}

.w-\[calc\(100\%-16px\)\]{
  width: calc(100% - 16px);
}

.w-\[calc\(33\.3\%-8px\)\]{
  width: calc(33.3% - 8px);
}

.w-\[calc\(33\.33\%-10\.66px\)\]{
  width: calc(33.33% - 10.66px);
}

.w-\[calc\(50\%-16px\)\]{
  width: calc(50% - 16px);
}

.w-\[calc\(50\%-8px\)\]{
  width: calc(50% - 8px);
}

.w-fit{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-full{
  width: 100%;
}

.min-w-0{
  min-width: 0px;
}

.min-w-11{
  min-width: 2.75rem;
}

.min-w-\[180px\]{
  min-width: 180px;
}

.min-w-\[8rem\]{
  min-width: 8rem;
}

.max-w-64{
  max-width: 16rem;
}

.max-w-\[140px\]{
  max-width: 140px;
}

.max-w-\[200px\]{
  max-width: 200px;
}

.max-w-\[280px\]{
  max-width: 280px;
}

.max-w-\[300px\]{
  max-width: 300px;
}

.max-w-lg{
  max-width: 32rem;
}

.flex-1{
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
}

.flex-auto{
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.flex-none{
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
}

.flex-shrink-0{
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.shrink{
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

.shrink-0{
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.grow-0{
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.translate-x-\[-4px\]{
  --tw-translate-x: -4px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\]{
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/3{
  --tw-translate-y: 33.333333%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-4px\]{
  --tw-translate-y: -4px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\]{
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[2px\]{
  --tw-translate-y: 2px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[3px\]{
  --tw-translate-y: 3px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180{
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform{
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-default{
  cursor: default;
}

.cursor-grab{
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing{
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cursor-not-allowed{
  cursor: not-allowed;
}

.cursor-pointer{
  cursor: pointer;
}

.touch-none{
  -ms-touch-action: none;
      touch-action: none;
}

.select-none{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.resize{
  resize: both;
}

.flex-row{
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-row-reverse{
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.flex-col{
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-col-reverse{
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.flex-wrap{
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.content-start{
  -ms-flex-line-pack: start;
      align-content: flex-start;
}

.items-start{
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.items-center{
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.items-baseline{
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.justify-end{
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.justify-center{
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.justify-between{
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.gap-1{
  gap: 0.25rem;
}

.gap-2{
  gap: 0.5rem;
}

.gap-3{
  gap: 0.75rem;
}

.gap-4{
  gap: 1rem;
}

.gap-5{
  gap: 1.25rem;
}

.gap-6{
  gap: 1.5rem;
}

.gap-\[12px\]{
  gap: 12px;
}

.gap-\[16px\]{
  gap: 16px;
}

.gap-\[18px\]{
  gap: 18px;
}

.gap-\[2px\]{
  gap: 2px;
}

.gap-\[30px\]{
  gap: 30px;
}

.gap-\[5px\]{
  gap: 5px;
}

.gap-\[6px\]{
  gap: 6px;
}

.gap-x-2{
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.gap-x-3{
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
}

.gap-y-2{
  row-gap: 0.5rem;
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.overflow-auto{
  overflow: auto;
}

.overflow-hidden{
  overflow: hidden;
}

.overflow-x-auto{
  overflow-x: auto;
}

.overflow-y-auto{
  overflow-y: auto;
}

.overflow-x-hidden{
  overflow-x: hidden;
}

.overflow-y-hidden{
  overflow-y: hidden;
}

.overflow-x-scroll{
  overflow-x: scroll;
}

.overflow-y-scroll{
  overflow-y: scroll;
}

.truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis{
  text-overflow: ellipsis;
}

.whitespace-nowrap{
  white-space: nowrap;
}

.text-nowrap{
  text-wrap: nowrap;
}

.break-all{
  word-break: break-all;
}

.rounded{
  border-radius: 0.25rem;
}

.rounded-\[12px\]{
  border-radius: 12px;
}

.rounded-\[16px\]{
  border-radius: 16px;
}

.rounded-\[4px\]{
  border-radius: 4px;
}

.rounded-\[8px\]{
  border-radius: 8px;
}

.rounded-\[inherit\]{
  border-radius: inherit;
}

.rounded-full{
  border-radius: 9999px;
}

.rounded-lg{
  border-radius: var(--radius);
}

.rounded-md{
  border-radius: calc(var(--radius) - 2px);
}

.rounded-sm{
  border-radius: calc(var(--radius) - 4px);
}

.rounded-b-\[8px\]{
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-l-\[8px\]{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.rounded-r-\[8px\]{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rounded-s-\[8px\]{
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
}

.rounded-t-\[8px\]{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-t-full{
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-bl-\[8px\]{
  border-bottom-left-radius: 8px;
}

.rounded-tr-\[8px\]{
  border-top-right-radius: 8px;
}

.rounded-tr-none{
  border-top-right-radius: 0px;
}

.border{
  border-width: 1px;
}

.border-2{
  border-width: 2px;
}

.border-\[2px\]{
  border-width: 2px;
}

.border-b{
  border-bottom-width: 1px;
}

.border-b-4{
  border-bottom-width: 4px;
}

.border-l{
  border-left-width: 1px;
}

.border-r{
  border-right-width: 1px;
}

.border-t{
  border-top-width: 1px;
}

.border-t-4{
  border-top-width: 4px;
}

.border-solid{
  border-style: solid;
}

.border-dashed{
  border-style: dashed;
}

.border-\[\#316EF5\]{
  --tw-border-opacity: 1;
  border-color: rgb(49 110 245 / var(--tw-border-opacity, 1));
}

.border-\[\#B6B8B9\]{
  --tw-border-opacity: 1;
  border-color: rgb(182 184 185 / var(--tw-border-opacity, 1));
}

.border-\[\#BFC6D2\]{
  --tw-border-opacity: 1;
  border-color: rgb(191 198 210 / var(--tw-border-opacity, 1));
}

.border-\[\#C9D0D9\]{
  --tw-border-opacity: 1;
  border-color: rgb(201 208 217 / var(--tw-border-opacity, 1));
}

.border-\[\#D6E2FD\]{
  --tw-border-opacity: 1;
  border-color: rgb(214 226 253 / var(--tw-border-opacity, 1));
}

.border-\[\#E5E7EA\]{
  --tw-border-opacity: 1;
  border-color: rgb(229 231 234 / var(--tw-border-opacity, 1));
}

.border-\[\#EBEDF0\]{
  --tw-border-opacity: 1;
  border-color: rgb(235 237 240 / var(--tw-border-opacity, 1));
}

.border-\[\#c9d0d9\]{
  --tw-border-opacity: 1;
  border-color: rgb(201 208 217 / var(--tw-border-opacity, 1));
}

.border-\[\#dfe6ec\]{
  --tw-border-opacity: 1;
  border-color: rgb(223 230 236 / var(--tw-border-opacity, 1));
}

.border-\[\#e9e9eb\]{
  --tw-border-opacity: 1;
  border-color: rgb(233 233 235 / var(--tw-border-opacity, 1));
}

.border-\[\#ebedf0\]{
  --tw-border-opacity: 1;
  border-color: rgb(235 237 240 / var(--tw-border-opacity, 1));
}

.border-\[hsl\(var\(--border\)\)\]{
  border-color: hsl(var(--border));
}

.border-\[rgba\(0\2c 0\2c 0\2c 0\.15\)\]{
  border-color: rgba(0,0,0,0.15);
}

.border-b-slate-100{
  --tw-border-opacity: 1;
  border-bottom-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.border-l-transparent{
  border-left-color: transparent;
}

.border-t-slate-100{
  --tw-border-opacity: 1;
  border-top-color: rgb(241 245 249 / var(--tw-border-opacity, 1));
}

.border-t-transparent{
  border-top-color: transparent;
}

.bg-\[\#316EF5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(49 110 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#52C41A\]{
  --tw-bg-opacity: 1;
  background-color: rgb(82 196 26 / var(--tw-bg-opacity, 1));
}

.bg-\[\#5ABCB0\]{
  --tw-bg-opacity: 1;
  background-color: rgb(90 188 176 / var(--tw-bg-opacity, 1));
}

.bg-\[\#626363\]{
  --tw-bg-opacity: 1;
  background-color: rgb(98 99 99 / var(--tw-bg-opacity, 1));
}

.bg-\[\#6B7A96\]{
  --tw-bg-opacity: 1;
  background-color: rgb(107 122 150 / var(--tw-bg-opacity, 1));
}

.bg-\[\#B6B8B9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(182 184 185 / var(--tw-bg-opacity, 1));
}

.bg-\[\#E8EDF7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(232 237 247 / var(--tw-bg-opacity, 1));
}

.bg-\[\#EAF0FE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(234 240 254 / var(--tw-bg-opacity, 1));
}

.bg-\[\#EFF1F5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(239 241 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F0F6FF\]{
  --tw-bg-opacity: 1;
  background-color: rgb(240 246 255 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F1F7FE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(241 247 254 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F4F6FB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F4F8FE\]{
  --tw-bg-opacity: 1;
  background-color: rgb(244 248 254 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F5F7FA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(245 247 250 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F7F7F7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F7F7F9\]{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 249 / var(--tw-bg-opacity, 1));
}

.bg-\[\#F8F9FB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FAFAFA\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FBFBFB\]{
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#FBFCFD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(251 252 253 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f4f4f5\]{
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f7f7f7\]{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f7f9fb\]{
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f8f9fb\]{
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity, 1));
}

.bg-\[\#f9fafc\]{
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity, 1));
}

.bg-\[\#fff\]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-\[rgba\(0\2c 0\2c 0\2c 0\.3\)\]{
  background-color: rgba(0,0,0,0.3);
}

.bg-\[rgba\(0\2c 0\2c 0\2c 0\.6\)\]{
  background-color: rgba(0,0,0,0.6);
}

.bg-\[rgba\(107\2c 122\2c 150\2c 0\.05\)\]{
  background-color: rgba(107,122,150,0.05);
}

.bg-\[rgba\(255\2c 255\2c 255\2c 0\.2\)\]{
  background-color: rgba(255,255,255,0.2);
}

.bg-\[rgba\(36\2c 45\2c 63\2c \.6\)\]{
  background-color: rgba(36,45,63,.6);
}

.bg-\[rgba\(44\2c 168\2c 61\2c 0\.1\)\]{
  background-color: rgba(44,168,61,0.1);
}

.bg-\[rgba\(49\2c 110\2c 245\2c 0\.1\)\]{
  background-color: rgba(49,110,245,0.1);
}

.bg-background{
  background-color: hsl(var(--background));
}

.bg-background\/80{
  background-color: hsl(var(--background) / 0.8);
}

.bg-border{
  background-color: hsl(var(--border));
}

.bg-gray-100{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-muted{
  background-color: hsl(var(--muted));
}

.bg-popover{
  background-color: hsl(var(--popover));
}

.bg-slate-400{
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1));
}

.bg-slate-600{
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity, 1));
}

.bg-transparent{
  background-color: transparent;
}

.bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.fill-current{
  fill: currentColor;
}

.object-contain{
  -o-object-fit: contain;
     object-fit: contain;
}

.object-cover{
  -o-object-fit: cover;
     object-fit: cover;
}

.object-scale-down{
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.p-0{
  padding: 0px;
}

.p-1{
  padding: 0.25rem;
}

.p-2{
  padding: 0.5rem;
}

.p-3{
  padding: 0.75rem;
}

.p-4{
  padding: 1rem;
}

.p-5{
  padding: 1.25rem;
}

.p-6{
  padding: 1.5rem;
}

.p-\[1px\]{
  padding: 1px;
}

.p-\[2px\]{
  padding: 2px;
}

.px-0{
  padding-left: 0px;
  padding-right: 0px;
}

.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[12px\]{
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[1px\]{
  padding-left: 1px;
  padding-right: 1px;
}

.px-\[25px\]{
  padding-left: 25px;
  padding-right: 25px;
}

.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[10px\]{
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[20px\]{
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[22px\]{
  padding-top: 22px;
  padding-bottom: 22px;
}

.py-\[5px\]{
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-\[6px\]{
  padding-top: 6px;
  padding-bottom: 6px;
}

.pb-1{
  padding-bottom: 0.25rem;
}

.pb-12{
  padding-bottom: 3rem;
}

.pb-2{
  padding-bottom: 0.5rem;
}

.pb-3{
  padding-bottom: 0.75rem;
}

.pb-32{
  padding-bottom: 8rem;
}

.pb-4{
  padding-bottom: 1rem;
}

.pb-6{
  padding-bottom: 1.5rem;
}

.pb-8{
  padding-bottom: 2rem;
}

.pl-1{
  padding-left: 0.25rem;
}

.pl-2{
  padding-left: 0.5rem;
}

.pl-3{
  padding-left: 0.75rem;
}

.pl-4{
  padding-left: 1rem;
}

.pl-5{
  padding-left: 1.25rem;
}

.pl-6{
  padding-left: 1.5rem;
}

.pl-8{
  padding-left: 2rem;
}

.pl-\[30px\]{
  padding-left: 30px;
}

.pl-\[34px\]{
  padding-left: 34px;
}

.pl-\[6px\]{
  padding-left: 6px;
}

.pr-0{
  padding-right: 0px;
}

.pr-2{
  padding-right: 0.5rem;
}

.pr-3{
  padding-right: 0.75rem;
}

.pr-4{
  padding-right: 1rem;
}

.pr-\[60px\]{
  padding-right: 60px;
}

.pt-0{
  padding-top: 0px;
}

.pt-2{
  padding-top: 0.5rem;
}

.pt-24{
  padding-top: 6rem;
}

.pt-3{
  padding-top: 0.75rem;
}

.pt-4{
  padding-top: 1rem;
}

.pt-6{
  padding-top: 1.5rem;
}

.pt-8{
  padding-top: 2rem;
}

.pt-\[80px\]{
  padding-top: 80px;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.align-middle{
  vertical-align: middle;
}

.text-\[10px\]{
  font-size: 10px;
}

.text-\[12px\]{
  font-size: 12px;
}

.text-\[13px\]{
  font-size: 13px;
}

.text-\[14px\]{
  font-size: 14px;
}

.text-\[16px\]{
  font-size: 16px;
}

.text-\[18px\]{
  font-size: 18px;
}

.text-\[24px\]{
  font-size: 24px;
}

.text-\[30px\]{
  font-size: 30px;
}

.text-base{
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg{
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xs{
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold{
  font-weight: 700;
}

.font-semibold{
  font-weight: 600;
}

.italic{
  font-style: italic;
}

.leading-8{
  line-height: 2rem;
}

.leading-\[30px\]{
  line-height: 30px;
}

.leading-none{
  line-height: 1;
}

.leading-normal{
  line-height: 1.5;
}

.tracking-tight{
  letter-spacing: -0.025em;
}

.tracking-widest{
  letter-spacing: 0.1em;
}

.text-\[\#1E1F21\]{
  --tw-text-opacity: 1;
  color: rgb(30 31 33 / var(--tw-text-opacity, 1));
}

.text-\[\#242D3F\]{
  --tw-text-opacity: 1;
  color: rgb(36 45 63 / var(--tw-text-opacity, 1));
}

.text-\[\#242E41\]{
  --tw-text-opacity: 1;
  color: rgb(36 46 65 / var(--tw-text-opacity, 1));
}

.text-\[\#242d3f\]{
  --tw-text-opacity: 1;
  color: rgb(36 45 63 / var(--tw-text-opacity, 1));
}

.text-\[\#2CA83D\]{
  --tw-text-opacity: 1;
  color: rgb(44 168 61 / var(--tw-text-opacity, 1));
}

.text-\[\#316EF5\]{
  --tw-text-opacity: 1;
  color: rgb(49 110 245 / var(--tw-text-opacity, 1));
}

.text-\[\#316ef5\]{
  --tw-text-opacity: 1;
  color: rgb(49 110 245 / var(--tw-text-opacity, 1));
}

.text-\[\#3D3D3D\]{
  --tw-text-opacity: 1;
  color: rgb(61 61 61 / var(--tw-text-opacity, 1));
}

.text-\[\#6B7A96\]{
  --tw-text-opacity: 1;
  color: rgb(107 122 150 / var(--tw-text-opacity, 1));
}

.text-\[\#6b7a96\]{
  --tw-text-opacity: 1;
  color: rgb(107 122 150 / var(--tw-text-opacity, 1));
}

.text-\[\#7286A1\]{
  --tw-text-opacity: 1;
  color: rgb(114 134 161 / var(--tw-text-opacity, 1));
}

.text-\[\#767C88\]{
  --tw-text-opacity: 1;
  color: rgb(118 124 136 / var(--tw-text-opacity, 1));
}

.text-\[\#939393\]{
  --tw-text-opacity: 1;
  color: rgb(147 147 147 / var(--tw-text-opacity, 1));
}

.text-\[\#B6B8B9\]{
  --tw-text-opacity: 1;
  color: rgb(182 184 185 / var(--tw-text-opacity, 1));
}

.text-\[\#B7BAC0\]{
  --tw-text-opacity: 1;
  color: rgb(183 186 192 / var(--tw-text-opacity, 1));
}

.text-\[\#BFC6D2\]{
  --tw-text-opacity: 1;
  color: rgb(191 198 210 / var(--tw-text-opacity, 1));
}

.text-\[\#C9D0D9\]{
  --tw-text-opacity: 1;
  color: rgb(201 208 217 / var(--tw-text-opacity, 1));
}

.text-\[\#EBEDF0\]{
  --tw-text-opacity: 1;
  color: rgb(235 237 240 / var(--tw-text-opacity, 1));
}

.text-\[\#F0655F\]{
  --tw-text-opacity: 1;
  color: rgb(240 101 95 / var(--tw-text-opacity, 1));
}

.text-\[\#F0665E\]{
  --tw-text-opacity: 1;
  color: rgb(240 102 94 / var(--tw-text-opacity, 1));
}

.text-\[\#b6b8b9\]{
  --tw-text-opacity: 1;
  color: rgb(182 184 185 / var(--tw-text-opacity, 1));
}

.text-\[\#b7bac0\]{
  --tw-text-opacity: 1;
  color: rgb(183 186 192 / var(--tw-text-opacity, 1));
}

.text-\[\#c9d0d9\]{
  --tw-text-opacity: 1;
  color: rgb(201 208 217 / var(--tw-text-opacity, 1));
}

.text-\[\#fff\]{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-black{
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-foreground{
  color: hsl(var(--foreground));
}

.text-gray-300{
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity, 1));
}

.text-gray-500{
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-950{
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity, 1));
}

.text-muted-foreground{
  color: hsl(var(--muted-foreground));
}

.text-popover-foreground{
  color: hsl(var(--popover-foreground));
}

.text-red-500{
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-slate-400{
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity, 1));
}

.text-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline{
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.line-through{
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.opacity-20{
  opacity: 0.2;
}

.opacity-30{
  opacity: 0.3;
}

.opacity-50{
  opacity: 0.5;
}

.opacity-60{
  opacity: 0.6;
}

.opacity-70{
  opacity: 0.7;
}

.opacity-80{
  opacity: 0.8;
}

.opacity-\[0\.2\]{
  opacity: 0.2;
}

.shadow{
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline{
  outline-style: solid;
}

.ring-offset-background{
  --tw-ring-offset-color: hsl(var(--background));
}

.blur{
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter{
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition{
  -webkit-transition-property: color, background-color, border-color, fill, stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.transition-colors{
  -webkit-transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.transition-opacity{
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.duration-200{
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

@-webkit-keyframes enter{
  from{
    opacity: var(--tw-enter-opacity, 1);
    -webkit-transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
            transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes enter{
  from{
    opacity: var(--tw-enter-opacity, 1);
    -webkit-transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
            transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@-webkit-keyframes exit{
  to{
    opacity: var(--tw-exit-opacity, 1);
    -webkit-transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
            transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

@keyframes exit{
  to{
    opacity: var(--tw-exit-opacity, 1);
    -webkit-transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
            transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.duration-200{
  -webkit-animation-duration: 200ms;
          animation-duration: 200ms;
}

.running{
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

.placeholder\:text-muted-foreground::-webkit-input-placeholder{
  color: hsl(var(--muted-foreground));
}

.placeholder\:text-muted-foreground::-moz-placeholder{
  color: hsl(var(--muted-foreground));
}

.placeholder\:text-muted-foreground:-ms-input-placeholder{
  color: hsl(var(--muted-foreground));
}

.placeholder\:text-muted-foreground::-ms-input-placeholder{
  color: hsl(var(--muted-foreground));
}

.placeholder\:text-muted-foreground::placeholder{
  color: hsl(var(--muted-foreground));
}

.hover\:bg-\[\#316EF5\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(49 110 245 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#626363\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(98 99 99 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#F1F7FE\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(241 247 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#F4F8FE\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(244 248 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#F7F7F9\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 249 / var(--tw-bg-opacity, 1));
}

.hover\:bg-\[\#FAFBFD\]:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 253 / var(--tw-bg-opacity, 1));
}

.hover\:bg-blue-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-100:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:text-\[\#316EF5\]:hover{
  --tw-text-opacity: 1;
  color: rgb(49 110 245 / var(--tw-text-opacity, 1));
}

.hover\:text-white:hover{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:opacity-100:hover{
  opacity: 1;
}

.focus\:bg-accent:focus{
  background-color: hsl(var(--accent));
}

.focus\:text-accent-foreground:focus{
  color: hsl(var(--accent-foreground));
}

.focus\:outline-none:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
          box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-ring:focus{
  --tw-ring-color: hsl(var(--ring));
}

.focus\:ring-offset-2:focus{
  --tw-ring-offset-width: 2px;
}

.active\:cursor-grabbing:active{
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.disabled\:pointer-events-none:disabled{
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled{
  cursor: not-allowed;
}

.disabled\:opacity-50:disabled{
  opacity: 0.5;
}

.group:hover .group-hover\:block{
  display: block;
}

.group:hover .group-hover\:flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.group:hover .group-hover\:hidden{
  display: none;
}

.group:hover .group-hover\:bg-\[\#FAFBFD\]{
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 253 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:bg-\[rgba\(49\2c 110\2c 245\2c 0\.05\)\]{
  background-color: rgba(49,110,245,0.05);
}

.group:hover .group-hover\:bg-white{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:text-\[\#316EF5\]{
  --tw-text-opacity: 1;
  color: rgb(49 110 245 / var(--tw-text-opacity, 1));
}

.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"]{
  pointer-events: none;
}

.data-\[disabled\]\:pointer-events-none[data-disabled]{
  pointer-events: none;
}

.data-\[selected\=true\]\:bg-accent[data-selected="true"]{
  background-color: hsl(var(--accent));
}

.data-\[state\=open\]\:bg-accent[data-state="open"]{
  background-color: hsl(var(--accent));
}

.data-\[selected\=true\]\:text-accent-foreground[data-selected="true"]{
  color: hsl(var(--accent-foreground));
}

.data-\[state\=open\]\:text-muted-foreground[data-state="open"]{
  color: hsl(var(--muted-foreground));
}

.data-\[disabled\=true\]\:opacity-50[data-disabled="true"]{
  opacity: 0.5;
}

.data-\[disabled\]\:opacity-50[data-disabled]{
  opacity: 0.5;
}

.data-\[state\=open\]\:animate-in[data-state="open"]{
  -webkit-animation-name: enter;
          animation-name: enter;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}

.data-\[state\=closed\]\:animate-out[data-state="closed"]{
  -webkit-animation-name: exit;
          animation-name: exit;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}

.data-\[state\=closed\]\:fade-out-0[data-state="closed"]{
  --tw-exit-opacity: 0;
}

.data-\[state\=open\]\:fade-in-0[data-state="open"]{
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state="closed"]{
  --tw-exit-scale: .95;
}

.data-\[state\=open\]\:zoom-in-95[data-state="open"]{
  --tw-enter-scale: .95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"]{
  --tw-enter-translate-y: -0.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side="left"]{
  --tw-enter-translate-x: 0.5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side="right"]{
  --tw-enter-translate-x: -0.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"]{
  --tw-enter-translate-y: 0.5rem;
}

.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"]{
  --tw-exit-translate-x: -50%;
}

.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"]{
  --tw-exit-translate-y: -48%;
}

.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"]{
  --tw-enter-translate-x: -50%;
}

.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"]{
  --tw-enter-translate-y: -48%;
}

@media (min-width: 640px){
  .sm\:flex-row{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .sm\:justify-end{
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:rounded-lg{
    border-radius: var(--radius);
  }

  .sm\:text-left{
    text-align: left;
  }
}

@media (min-width: 768px){
  .md\:w-full{
    width: 100%;
  }
}

.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading]{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading]{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading]{
  font-size: 0.75rem;
  line-height: 1rem;
}

.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading]{
  font-weight: 500;
}

.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading]{
  color: hsl(var(--muted-foreground));
}

.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~[cmdk-group]{
  padding-top: 0px;
}

.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group]{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg{
  height: 1.25rem;
}

.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg{
  width: 1.25rem;
}

.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input]{
  height: 3rem;
}

.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item]{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item]{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg{
  height: 1.25rem;
}

.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg{
  width: 1.25rem;
}

.\[\&_svg\]\:pointer-events-none svg{
  pointer-events: none;
}

.\[\&_svg\]\:size-4 svg{
  width: 1rem;
  height: 1rem;
}

.\[\&_svg\]\:shrink-0 svg{
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
