.tableContainer {
  th {
    background-color: var(--title-bg) !important;
    // border-bottom-color: var(--horizontal-thborder) !important;
    // border-right-color: var(--vertical-thborder) !important;
    border-bottom-color: #d8dde5 !important;
    border-right-color: #d8dde5 !important;
    border-radius: 0px !important;
  }

  :global {
    th.ant-table-cell {
      padding: var(--title-lineHeight) !important;
    }
    td.ant-table-cell {
      padding: var(--content-lineHeight) !important;
    }
  }

  td {
    background-color: var(--content-bg) !important;
    border-bottom-color: var(--horizontal-tdborder) !important;
    border-right-color: var(--vertical-tdborder) !important;
  }

  :global {
    .ant-table-expanded-row-fixed {
      background-color: var(--gap-bg) !important;
    }

    .ant-table-container {
      border-radius: 0px !important;
    }
  }

  table {
    border-radius: 0px !important;
  }
}

.tooltip {
  :global {
    .ant-tooltip-content {
      top: 16px;
    }
    .ant-tooltip-arrow {
      display: none;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;