.customAttribute {
  width: 100%;
  padding: 16px;
  background-color: #fff;
}

.defaultGroupName {
  display: flex;
  align-items: center;
  margin: 12px 0;
  color: #767c88;
  font-weight: bold;
  font-size: 12px;
}

.style1GroupName {
  margin: 12px 0;
  padding: 2px 0 2px 12px;
  color: #242d3f;
  font-weight: bold;
  font-size: 12px;
  background-color: rgba(49, 110, 245, 0.1);
  border-radius: 4px;
}
.style2GroupName {
  margin: 12px 0;
  color: #242d3f;
  font-weight: 600;
  font-size: 16px;
}

.style3GroupName {
  margin: 12px 0;
  padding: 2px 0 2px 12px;
  color: #242d3f;
  font-weight: bold;
  font-size: 12px;
  background-color: rgba(49, 110, 245, 0.1);
  border-radius: 4px;
}

.rect {
  width: 4px;
  height: 12px;
  margin-right: 4px;
  background-color: #316ef5;
}

.prop {
  display: flex;
  margin-bottom: 2px;

  .propName {
    display: flex;
    align-items: center;
    width: 150px;
    padding: 8px 16px 8px 16px;
    color: #767c88;
    font-size: 12px;
    background-color: #f8f9fb;

    span {
      display: block;
      margin-left: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .propInput {
    flex: 1;
    padding: 8px 16px 8px 16px;
    color: #767c88;
    font-size: 12px;
    background-color: #f8f9fb;
  }
}

.style1Prop {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  overflow: hidden;
  border: 1px dashed transparent;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    padding-right: 26px;
    border-color: #c9d0d9;
    :global {
      .draggableIcon {
        display: flex;
      }
    }
  }

  .label {
    width: 90px;
    padding-right: 12px;
    overflow: hidden;
    color: #767c88;
    font-size: 12px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    // margin-right: 6px;
  }

  .propContent {
    flex: 1;
    color: #b6b8b9;
    font-size: 12px;
    border-bottom: 1px solid #ebedf0;
  }

  :global {
    .draggableIcon {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      align-items: center;
      width: 30px;
      height: 38px;
      padding-left: 4px;
      background-color: white;
      cursor: pointer;
    }
  }
}

.style2Prop {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 50px;
  overflow: hidden;
  border: 1px dashed transparent;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    padding-right: 26px;
    border-color: #c9d0d9;
    :global {
      .draggableIcon {
        display: flex;
      }
    }
  }

  .attrName {
    height: 20px;
    :global {
      .iconfont {
        color: #9da7b8;
        font-size: 12px;
      }
    }
    span {
      color: #9da7b8;
      font-size: 12px;
    }
  }

  .propValue {
    height: 30px;
    padding-left: 6px;
    color: #242d3f;
    font-size: 12px;
    line-height: 30px;
    border: 1px solid #dededf;
    border-radius: 8px;
  }

  :global {
    .draggableIcon {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      align-items: center;
      width: 30px;
      height: 50px;
      padding-left: 4px;
      background-color: white;
      cursor: pointer;
    }
  }
}

.style3Prop {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  overflow: hidden;
  border: 1px dashed transparent;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    padding-right: 26px;
    border-color: #c9d0d9;
    :global {
      .draggableIcon {
        display: flex;
      }
    }
  }

  .label {
    width: 90px;
    padding-right: 12px;
    overflow: hidden;
    color: #767c88;
    font-size: 12px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    // margin-right: 6px;
  }

  .propContent {
    flex: 1;
    height: 30px;
    padding-left: 6px;
    color: #b6b8b9;
    font-size: 12px;
    line-height: 30px;
    border: 1px solid #dededf;
    border-radius: 8px;
  }

  :global {
    .draggableIcon {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      align-items: center;
      width: 30px;
      height: 38px;
      padding-left: 4px;
      background-color: white;
      cursor: pointer;
    }
  }
}

:global {
  .react-grid-item {
    .react-resizable-handle {
      right: -3px;
      bottom: -3px;
      width: 20px;
      height: 20px;
      padding: 0;
      overflow: hidden;
      background-image: none;

      &::after {
        position: absolute;
        right: 7px;
        bottom: 6px;
        width: 20px;
        height: 20px;
        border-right: 2px solid transparent;
        border-bottom: 2px solid transparent;
        border-radius: 8px;
        content: '';
      }
    }
  }

  .react-grid-item:hover {
    .react-resizable-handle::after {
      border-color: #316ef5;
    }
  }
}

.tool-popover {
  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}

@root-entry-name: default;@primary-2: #F8F9FB;@primary-color: #316EF5;@error-color: #F0665E;@border-radius-base: 8px;@border-color-base: #EBEDF0;@input-placeholder-color: #B7BAC0;@checkbox-border-radius: 2px;@btn-border-radius-base: 4px;@btn-border-radius-sm: 4px;